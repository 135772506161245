import { differenceInYears, isAfter, isBefore, parseISO } from 'date-fns';
import { optionPresets } from '@app/forms';
import {
  toDate,
  eighteenYearsAgo,
  getFullName,
  todayISO,
  lastFour,
  firstDayOfNextMonth,
  safeFormatDate,
  firstDayOfSomeMonth,
  threeMonthsFromNowISO,
} from '@app/utils';
import { Trinary } from '@app/data';
import { env } from '@app/global';

const isValidDate = (date) => {
  const d = new Date(date);
  return /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(d.getTime());
};

// const isOlderThan12 = (dob) => isBefore(toDate(dob), twelveYearsAgo());

const copyGA = (id, isGA) => ({ id: `${id}${isGA ? '_GA' : ''}` });

export const fields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'name',
    type: 'legalName',
    required: true,
    label: { id: '4_Q' },
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'dob',
    type: 'date',
    dob: true,
    required: true,
    label: { id: '33_L' },
    format: 'YYYY-MM-DD',
    dependencies: ['dob', 'name'],
    fieldAddition: ({ dob, name }) => {
      if (isValidDate(dob)) {
        const age = differenceInYears(new Date(), parseISO(dob));

        if (age >= 64) {
          const fullName = getFullName(name, '');
          return {
            component: 'BANNER',
            props: {
              title:
                age === 64
                  ? fullName + ' will likely be eligible to enroll in Medicare when they turn 65.'
                  : fullName + ' was likely eligible to enroll in Medicare when they turned 65.',
              subtitle: "We'll ask about Medicare enrollment later in the application.",
              bg: 'mediumLight',
            },
          };
        }
      }
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSmoker',
    type: 'radio',
    required: true,
    dependencies: ['relation', 'name', 'dob', 'isRequestingCoverage'],
    // show if valid dob and more than 12 years old
    display: ({ isRequestingCoverage, dob }) =>
      isRequestingCoverage === 'YES' &&
      isValidDate(dob) &&
      isBefore(toDate(dob), eighteenYearsAgo()),
    options: optionPresets.YES_NO_TRINARY,
    label: (values) => ({
      id: '145_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '145_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'lastTobaccoUseDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    required: true,
    dependencies: ['isSmoker'],
    display: (values) => values.isSmoker === 'YES',
    label: { id: '146_Q' },
    help: { id: '146_T' },
    before: { date: todayISO(), message: 'Must be before today' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'filerRelation',
    type: 'dropdown',
    required: true,
    dependencies: ['relation', 'filerName'],
    display: (values) => values.relation === 'CHILD',
    label: (values) => ({
      id: '28_Q',
      data: { fullName: values.filerName },
    }),
    options: optionPresets.CHILD_RELATION,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'spouseRelation',
    type: 'dropdown',
    required: true,
    dependencies: ['relation', 'spouseName'],
    display: (values) => values.relation === 'CHILD' && !!values.spouseName,
    label: (values) => ({ id: '28_Q', data: { fullName: values.spouseName } }),
    options: optionPresets.CHILD_RELATION,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'sex',
    type: 'dropdown',
    required: true,
    label: { id: '31_Q' },
    options: optionPresets.SEX,
    // help: { id: '31_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssn',
    type: 'ssn',
    dependencies: ['isRequestingCoverage', 'relation', 'name', 'id', 'SSNs'],
    optional: (values) => values.isRequestingCoverage === 'NO',
    label: (values) => ({ id: values.isRequestingCoverage === 'YES' ? '32_L' : '34_Q' }),
    help: (values) => ({
      id: values.isRequestingCoverage === 'YES' ? '32_T' : '34_T',
      data: { name: getFullName(values.name, 'this person') },
    }),
    doesNotExistIn: ({ SSNs, id }) => ({
      array: SSNs.filter((member) => !!member.ssn && member.id !== id).map((member) => member.ssn),
      mask: (value) => `***-**-${lastFour(value)}`,
      message: 'This SSN matches another member on the application',
    }),
    editable: true,
  },
  {
    testID: '32_SKIP',
    copy: 'catch.ede.HealthEnrollment',
    name: 'confirmMissingSSN',
    type: 'checkbox',
    dependencies: ['isRequestingCoverage', 'ssn', 'name'],
    display: ({ ssn, isRequestingCoverage }) => !ssn && isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '32_CONFIRM',
      data: { fullName: getFullName(values?.name, 'this person') },
    }),
    required: ({ ssn }) => !ssn,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isCitizen',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isRequestingCoverage', 'relation', 'name'],
    display: (values) => values.isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '38_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '38_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSameNameApplicationAndDocument',
    type: 'radio',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['ssn', 'relation', 'name'],
    display: (values) => !!values.ssn,
    label: (values) => ({
      id: '36_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssnAlternateName',
    prefix: true,
    type: 'legalName',
    required: true,
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      suffix: true,
    },
    dependencies: ['isSameNameApplicationAndDocument'],
    display: (values) => values.isSameNameApplicationAndDocument === 'NO',
    label: { id: '37_Q' },
    help: { id: '37_T' },
  },
  {
    type: 'section',
    title: 'Medicare enrollment',
    // only show this section if its in dev OR after mar 21, 2025
    display: () => {
      return !env.IS_PROD || isAfter(new Date(), parseISO('2025-03-21'));
    },
    fields: [
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'isMedicareEnrolled',
        type: 'radio',
        options: optionPresets.YES_NO_TRINARY,
        required: false,
        dependencies: ['isGA', 'name'],
        display: ({ isGA }) => !isGA,
        label: ({ name }) => ({
          id: '316_Q',
          data: { fullName: getFullName(name, 'this person') },
        }),
        sublabel:
          'Most people enroll in Medicare when they turn 65, but some people are eligible earlier.',
        help: {
          id: '316_T',
          data: {
            firstDayOfFourthMonth: safeFormatDate(firstDayOfSomeMonth(4), 'MMMM d, yyyy'),
          },
        },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'isMedicareEnrolledAlert',
        type: 'label',
        dependencies: ['isMedicareEnrolled', 'coverageState', 'medicaidName'],
        label: ({ coverageState, medicaidName }) => ({
          id: '316_Q_ALERT',
          data: { coverageState, medicaidName },
        }),
        help: { id: '316_T_ALERT' },
        fieldAddition: ({ isMedicareEnrolled }) => {
          if (isMedicareEnrolled === Trinary.No) {
            return {
              component: 'BANNER',
              props: {
                title:
                  "It's important to sign up for Medicare coverage during your Initial Enrollment Period, if you're eligible.",
                subtitle:
                  "If you don't, you may have to pay a late enrollment penalty. Learn more about Medicare enrollment.",
                bg: 'mediumLight',
              },
            };
          }
        },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'medicareStartDate',
        type: 'date',
        dependencies: ['isMedicareEnrolled', 'isGA', 'name', 'dob'],
        format: 'YYYY-MM-DD',
        display: ({ isGA, isMedicareEnrolled }) => !isGA && isMedicareEnrolled === Trinary.Yes,
        required: true,
        validateDate: (value) => {
          const day = value.split('-')[2];

          if (day !== '01') {
            return 'Medicare must start on the first of the month';
          }
        },
        before: {
          date: threeMonthsFromNowISO(),
          inclusive: true,
          message: 'Must be within three months',
        },
        after: (values) => {
          if (values.dob) {
            return {
              date: values.dob,
              message: 'Must be after their date of birth',
            };
          }
        },
        label: ({ name }) => ({
          id: '317_Q',
          data: { fullName: getFullName(name, 'this person') },
        }),
        help: {
          id: '317_T',
          data: { firstDayOfNextMonth: safeFormatDate(firstDayOfNextMonth(), 'MMMM d, yyyy') },
        },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'medicareConfirmOtherCoverage',
        type: 'checkbox',
        dependencies: ['medicareStartDate', 'isGA', 'name'],
        display: ({ isGA, medicareStartDate }) => {
          const firstDayOfSecondMonth = safeFormatDate(firstDayOfSomeMonth(2), 'yyyy-MM-dd');
          const firstDayOfThirdMonth = safeFormatDate(firstDayOfSomeMonth(3), 'yyyy-MM-dd');

          return (
            !isGA &&
            (medicareStartDate === firstDayOfSecondMonth ||
              medicareStartDate === firstDayOfThirdMonth)
          );
        },
        precontentTitle: ({ name }) => ({
          id: '318_Q',
          data: { fullName: getFullName(name, 'this person') },
        }),
        label: ({ name }) => ({
          id: '318_L',
          data: { fullName: getFullName(name, 'This person') },
        }),
        help: { id: '318_T' },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'medicareCoverageOptions',
        type: 'radio',
        dependencies: ['medicareConfirmOtherCoverage', 'isGA', 'name'],
        display: ({ medicareConfirmOtherCoverage, isGA }) => {
          return !isGA && medicareConfirmOtherCoverage === true;
        },
        label: ({ name }) => ({
          id: '319_Q',
          data: { fullName: getFullName(name, 'this person') },
        }),
        options: ({ name }) => {
          const fullName = getFullName(name, 'This person');

          return [
            {
              label: { id: '319_Q.REC', data: { fullName } },
              value: 'REC',
            },
            {
              label: { id: '319_Q.BOTH', data: { fullName } },
              value: 'BOTH',
            },
            {
              label: { id: '319_Q.START', data: { fullName } },
              value: 'START',
            },
          ];
        },
        help: { id: '319_T' },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'medicareDualEnrollment',
        type: 'checkbox',
        dependencies: ['medicareStartDate', 'isGA', 'name'],
        display: ({ isGA, medicareStartDate }) => {
          const previousStart = isBefore(toDate(medicareStartDate), new Date());
          const upcomingStart = safeFormatDate(firstDayOfNextMonth(), 'yyyy-MM-dd');
          const hasCorrectDate = previousStart || upcomingStart === medicareStartDate;
          return !isGA && isValidDate(medicareStartDate) && hasCorrectDate;
        },
        precontentTitle: ({ name }) => ({
          id: '320_Q',
          data: { fullName: getFullName(name, 'this person') },
        }),
        label: ({ name }) => ({
          id: '320_L',
          data: { fullName: getFullName(name, 'this person') },
        }),
        help: { id: '320_T' },
      },
    ],
  },
  {
    type: 'section',
    title: 'Optional demographic information',
    fields: [
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'isBlindOrDisabled',
        type: 'radio',
        options: optionPresets.YES_NO_TRINARY,
        dependencies: [
          'isRequestingCoverage',
          'isRequestingFinancialAssistance',
          'relation',
          'name',
        ],
        display: (values) =>
          values.isRequestingCoverage === 'YES' && values.isRequestingFinancialAssistance,
        required: false,
        label: (values) => ({
          id: '131_Q',
          data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
        }),
        help: { id: '131_T' },
      },
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'isLongTermCare',
        type: 'radio',
        options: optionPresets.YES_NO_TRINARY,
        dependencies: [
          'isRequestingCoverage',
          'isRequestingFinancialAssistance',
          'relation',
          'name',
        ],
        display: (values) =>
          values.isRequestingCoverage === 'YES' && values.isRequestingFinancialAssistance,
        required: false,
        label: (values) => ({
          id: '132_Q',
          data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
        }),
        help: { id: '132_T' },
      },

      {
        copy: 'catch.ede',
        name: 'sexAssignedAtBirth',
        type: 'dropdown',
        options: optionPresets.SEX_ASSIGNED_AT_BIRTH,
        required: false,
        optional: true,
        grouped: true,
        dependencies: ['dob', 'name', 'coverageYear', 'isGA'],
        display: false,
        // display: ({ dob, coverageYear, isGA }) =>
        //   !isGA && isOlderThan12(dob) && coverageYear >= 2024,
        label: (values) => ({
          id: 'HealthEnrollment.306_Q',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
        help: (values) => ({
          id: 'HealthEnrollment.306_T',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
      },
      {
        copy: 'catch.ede',
        name: 'sexAssignedAtBirthOtherText',
        type: 'text',
        dependencies: ['sexAssignedAtBirth', 'isGA'],
        display: false,
        // display: (values) => !values.isGA && values.sexAssignedAtBirth === SexAssignedAtBirth.OTHER,
        placeholder: { id: 'enums.ASSIGNED_SEX' },
        regex: {
          value: /^[A-Za-z0-9 '.-]+$/,
          message: 'No special characters',
        },
      },
      {
        copy: 'catch.ede',
        name: 'gender',
        type: 'dropdown',
        options: optionPresets.GENDER,
        required: false,
        optional: true,
        grouped: true,
        dependencies: ['dob', 'name', 'coverageYear', 'isGA'],
        display: false,
        // display: ({ dob, coverageYear, isGA }) =>
        //   !isGA && isOlderThan12(dob) && coverageYear >= 2024,
        label: (values) => ({
          id: 'HealthEnrollment.307_Q',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
        help: (values) => ({
          id: 'HealthEnrollment.306_T',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
      },
      {
        copy: 'catch.ede',
        name: 'genderDifferentTermText',
        type: 'text',
        dependencies: ['gender', 'isGA'],
        display: false,
        // display: (values) => !values.isGA && values.gender === Gender.DIFFERENT_TERM,
        placeholder: { id: 'enums.GENDER' },
        regex: {
          value: /^[A-Za-z0-9 '.-]+$/,
          message: 'No special characters',
        },
      },
      {
        copy: 'catch.ede',
        name: 'sexualOrientation',
        type: 'dropdown',
        options: optionPresets.SEXUAL_ORIENTATION,
        required: false,
        optional: true,
        dependencies: ['dob', 'name', 'coverageYear', 'isGA'],
        grouped: true,
        display: false,
        // display: ({ dob, coverageYear, isGA }) =>
        //   !isGA && isOlderThan12(dob) && coverageYear >= 2024,
        label: (values) => ({
          id: 'HealthEnrollment.308_Q',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
        help: (values) => ({
          id: 'HealthEnrollment.306_T',
          data: { fullName: getFullName(values.name, 'this person') },
        }),
      },
      {
        copy: 'catch.ede',
        name: 'sexualOrientationDifferentTermText',
        type: 'text',
        dependencies: ['sexualOrientation'],
        display: false,
        // display: (values) => values.sexualOrientation === SexualOrientation.DIFFERENT_TERM,
        placeholder: { id: 'enums.SEXUAL_ORIENTATION' },
        regex: {
          value: /^[A-Za-z0-9 '.-]+$/,
          message: 'No special characters',
        },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'isHispanicOrigin',
        type: 'radio',
        optional: true,
        dependencies: ['relation', 'name', 'isGA'],
        options: optionPresets.YES_NO_TRINARY,
        required: false,
        label: (values) => ({
          id: '128_L',
          data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
        }),
        help: ({ isGA }) => copyGA('128_T', isGA),
      },
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'ethnicity',
        type: 'dropdown',
        options: optionPresets.ETHNICITY,
        dependencies: ['isHispanicOrigin'],
        display: (values) => values.isHispanicOrigin === 'YES',
        label: { id: '129_Q' },
        help: { id: '129_T' },
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'otherEthnicity',
        type: 'text',
        dependencies: ['ethnicity'],
        display: (values) => values.ethnicity === 'OTHER',
        placeholder: 'Other ethnicity',
      },
      {
        optional: true,
        copy: 'catch.ede.HealthEnrollment',
        name: 'race',
        type: 'multiSelect',
        style: 'picker',
        label: { id: '130_Q' },
        required: false,
        options: optionPresets.RACE,
      },
      {
        name: 'otherRaceText',
        type: 'text',
        dependencies: ['race'],
        display: ({ race }) => race?.includes('OTHER'),
        placeholder: 'Race description',
      },
    ],
  },
];
